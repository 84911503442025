import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import room from './room/reducer'
import thunk from 'redux-thunk'

const reducers = combineReducers({
  room
})

const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)))

export default store
