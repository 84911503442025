
import * as actionTypes from './actionTypes'
const initialState = {
  controlPanel: true,
  activeTab: 1,
  activeEmoji: 0,
  participants: [],
  layout: 'default',
  remoteData: null
}

const room = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionTypes.OPEN_CONTROL_PANEL:
      return {
        ...state,
        controlPanel: action.payload
      }

    case actionTypes.SET_PARTICIPANTS:
      return {
        ...state,
        participants: action.payload
      }
    case actionTypes.ADD_PARTICIPANT:
      return {
        ...state,
        participants: action.payload
      }
    case actionTypes.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload
      }
    case actionTypes.SET_ACTIVE_EMOJI:
      return {
        ...state,
        activeEmoji: action.payload
      }
    case actionTypes.SET_LAYOUT: {
      return {
        ...state,
        layout: action.payload
      }
    }
    case actionTypes.SET_REMOTE_DATA: {
      return {
        ...state,
        remoteData: action.payload
      }
    }
  }
  return state
}

export default room
