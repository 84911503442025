import React, { useRef, useEffect } from 'react'
import './participantList.css'
import { BTN_DOWN } from '../../constants/keyboardCode'
import { useSelector } from 'react-redux'
import Video from '../Video/Video'

const ParticipantList = () => {
  const buttonRef = useRef(null)
  const participants = useSelector(state => state.room.participants)

  useEffect(() => {
    window.addEventListener('keydown', handleButtonPress, false)

    return () => window.removeEventListener('keydown', handleButtonPress, false)
  }, [])

  const handleButtonPress = (event) => {
    if (event.keyCode === BTN_DOWN) {
      buttonRef.current.focus()
    }
  }

  const renderContent = () => {
    if (!participants.length) {
      return (
        <div className='participant_control_container'>
          <h2>
          </h2>
        </div>
      )
    } else {
      return (
        <>
          {participants.map(participant => (
            <Video key={participant.participantId} participant={participant} />
          ))}
        </>
      )
    }
  }

  return (
    <>
      {renderContent()}
    </>
  )
}
export default ParticipantList
