/* eslint-disable */
import React, { useEffect, useRef, useMemo } from 'react'
import WT from '@sscale/wtsdk'
import './Video.css'
import {useSelector} from "react-redux";

function Video ({ participant }) {
  const imageVideoRef = useRef(null)
  const imageAudioRef = useRef(null)
  const refVideo = useRef(null)
  const { layout, remoteData } = useSelector(state => state.room)
  const styleRef = useRef('default')

  useEffect(() => {
    styleRef.current = layout;
  }, [layout])

  useEffect(() => {
    WT.ParticipantListeners.onParticipantMediaStreamChanged((participantId, type, state) => {
      console.log("Media stream changed ", participantId, type, state)
      if (participantId === participant.participantId) {
        if (type === 'VIDEO') {
          if (state === 'DISABLED') {
            imageVideoRef.current.src = process.env.PUBLIC_URL + '/camera-off.svg'
          } else {
            imageVideoRef.current.src = process.env.PUBLIC_URL + '/camera.svg'
          }
        } else if (type === 'AUDIO') {
          if (state === 'DISABLED') {
            imageAudioRef.current.src = process.env.PUBLIC_URL + '/mic-off.svg'
          } else {
            imageAudioRef.current.src = process.env.PUBLIC_URL + '/mic.svg'
          }
        }
      }
    })

    WT.ParticipantListeners.onParticipantSpeaking((participantId) => {
      if (participantId === participant.participantId) {
        const elem = document.getElementById(`video-container-${participant.participantId}`)

        if (elem) {
          elem.classList.add('active-speaker')
        }
      }
    })

    WT.ParticipantListeners.onParticipantStopSpeaking((participantId) => {
      if (participantId === participant.participantId) {
        const elem = document.getElementById(`video-container-${participant.participantId}`)

        if (elem) {
          elem.classList.remove('active-speaker')
        }
      }
    })
  }, [participant.participantId])

  const memoizedVideo = useMemo(() => {
    return (
      <video
        className={layout}
        id={'participant-' + participant.participantId}
        playsInline
        pip={"true"}
        autoPlay
        muted
        disablePictureInPicture
        ref={(r) => {
          if (r) {
            if(remoteData){
              const dataFrom = JSON.parse(remoteData)
              if(r.id === `participant-${dataFrom.participantId}`){
                r.muted = true;
              }
            }
            refVideo.current = r;
            r.srcObject = participant.stream
          }
        }}
      >
        <source src={participant.stream} type="video/webm" />
      </video>
    )
  }, [participant.participantId, participant.stream, layout])

  if (!participant.stream) {
    return (<></>)
  }

  return (
    <div id={`video-container-${participant.participantId}`} className='video-container' key={participant.participantId}>
      {memoizedVideo}
      <div className='control-video-block'>

        <div className='participant-name'>
          <div>{participant.participantName}</div>
        </div>
      </div>
    </div>
  )
}

export default Video
