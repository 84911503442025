import React, {useEffect} from 'react'
import './participant.css'
import ParticipantList from '../ParticipantList/ParticipantList'
import {useDispatch, useSelector} from 'react-redux'
import {setLayout} from "../../redux/room/actionCreator";

const Participant = ({layoutType = 'grid' }) => {

  const participants = useSelector(state => state.room.participants)
  const { layout } = useSelector(state => state.room)
  const dispatch = useDispatch();

  useEffect(() => {
    if(participants.length === 2){
      dispatch(setLayout('default'))
    }
    if(participants.length === 3){
      dispatch(setLayout('default3'))
    }
  }, [participants])

  return <>
      {
          layoutType ==='grid' ?
              <div className={participants.length > 3 ? 'for_p_container_' + layout : 'participant_container_' + layout}>
                  <ParticipantList />
              </div>:
              <div className={'participant_container_' + layout}>
                <ParticipantList />
              </div>
      }
  </>
}
export default Participant
