const BTN_LEFT = 37
const BTN_UP = 38
const BTN_RIGHT = 39
const BTN_DOWN = 40
const BTN_OK = 13
const INFO = 73
const PLAY = 179
const CHANEL_UP = 228
const CHANEL_DOWN = 227

export {
  BTN_LEFT,
  BTN_UP,
  BTN_RIGHT,
  BTN_DOWN,
  BTN_OK,
  INFO,
  PLAY,
  CHANEL_UP,
  CHANEL_DOWN
}
