import axios from 'axios'

const authRequest = axios.create({
  baseURL: process.env.REACT_APP_AUTH_CLUSTER_URL,
  headers: {
    'Content-Type': 'application/json',
    'Auth-Token': process.env.REACT_APP_CLUSTER_TOKEN
  }
})

export default authRequest
