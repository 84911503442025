import React, {useEffect, useRef, useState} from 'react'
import './App.css'
import Participant from './components/Participants/Participants'
import authRequest from './HTTP'
import WT from '@sscale/wtsdk'
import { useDispatch, useSelector } from 'react-redux'
import {
  addParticipant,
  openPanel, saveRemoteData,
  setLayout,
  setParticipants
} from './redux/room/actionCreator'
import QRCode from "react-qr-code";
import {uid} from "./helpers/uuid";

function App () {
  let keyPressed = false;
  const dispatch = useDispatch()
  const { controlPanel, activeTab, participants, activeEmoji } = useSelector(state => state.room)
  const isShownRef = useRef(controlPanel)
  const participantsRef = useRef(participants)
  const activeTabRef = useRef(activeTab)
  const activeEmojiRef = useRef(activeEmoji)

  const qrStateRef = useRef(true)
  const qrValueRef = useRef(uid())
  const remoteQRRef = useRef(null)
  const lasIdRef = useRef(null)

  const [qrState, setQrState ] = useState(true)


  let layoutType = (new URLSearchParams(window.location.search)).get("layout")

  useEffect(() => {
    participantsRef.current = participants
  }, [participants])


  useEffect(() => {
    activeTabRef.current = activeTab
  }, [activeTab])
  useEffect(() => {
    activeEmojiRef.current = activeEmoji
  }, [activeEmoji])

  useEffect(() => {
    isShownRef.current = controlPanel
  }, [controlPanel])

  useEffect(() => {
    async function doAuth () {
      const response = await authRequest.post('/token/v2/', {
        room_name: 'comcast_demo_room'
      })

      WT.Session.connectAsAViewer(response.data.streaming_token, 'box_viewer')
    }

    WT.SessionListeners.onConnected((exParticipants) => {
      // console.log('exParticipants ', exParticipants)
      // dispatch(setParticipants(exParticipants))
    })
    WT.ReconnectListeners.onRemoteParticipantReconnected((params) => {
      if(params === lasIdRef.current){
        qrStateRef.current = false;
        setQrState(false)
        lasIdRef.current = null;
      }else {
        qrStateRef.current = true;
        setQrState(true)
        remoteQRRef.current = null;
      }
    })

    WT.SessionListeners.onStreamCreated((params) => {
      dispatch(addParticipant(params))
      dispatch(openPanel(true))
    })

    WT.ParticipantListeners.onParticipantLeft((params) => {
      const newParticipants = participantsRef.current.filter(p => p.participantId !== params.participantId)
      dispatch(setParticipants(newParticipants))
      let local = {
        participantId: null
      }
      if(remoteQRRef.current) {
        local = JSON.parse(remoteQRRef.current)
        lasIdRef.current = local.participantId;
      }

      if( params.participantId === local.participantId){
        console.log(" Make default ")
        // qrValueRef.current = uid();
        qrStateRef.current = true;
        setQrState(true)
        remoteQRRef.current = null;
      }

    })
    WT.SessionListeners.onMessageReceived(data => {
      const { message } = data
      const {qrData } = JSON.parse(message)
      if(qrValueRef.current === qrData){
        qrStateRef.current = false;
        setQrState(false)
        remoteQRRef.current = message;
        dispatch(saveRemoteData(message))
      }
    })

    doAuth()
    window.addEventListener('keydown', handleKeydown, false)

    window.$badger.dismissLoadingScreen()

    return () => {
      window.removeEventListener('keydown', handleKeydown, false)
    }
  }, [])

  const handleKeydown = (event) => {

    if(event.keyCode === 48)
      dispatch(setLayout('default'))

      if(!keyPressed){
        setInterval(() => {
          let messageObj = {
            participantId: null
          }
          if(remoteQRRef.current)
           messageObj  = JSON.parse(remoteQRRef.current)

          const listOf = document.getElementsByTagName('video');

          for (let item of listOf){
            if(item.id === `participant-${messageObj.participantId}`){
              item.muted = true
            }else {
              item.muted = false;
            }
            if(qrStateRef.current){
              item.muted = true;
            }
          }

        }, 1000)
      }
  }
  return (
    <div className='App-container'>
      <div id={'key-code'}></div>
      <div className='Content-container'>
        <div className={'sceenic-logo'}> </div>
        {renderQR(qrState, qrValueRef.current)}
        {controlPanel && (
          <Participant layoutType={layoutType} />
        )}
      </div>
    </div>
  )
}

function renderQR(state, value) {

  if(state) return <div className={'qr-container'}>
    <QRCode value={value} />
  </div>

}

export default App
