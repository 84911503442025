import * as actionTypes from './actionTypes'

export const openPanel = (status) => (dispatch) => {
  const action = {
    type: actionTypes.OPEN_CONTROL_PANEL,
    payload: status
  }
  dispatch(action)
}

export const setParticipants = (participantList) => (dispatch) => {
  const action = {
    type: actionTypes.SET_PARTICIPANTS,
    payload: participantList
  }
  dispatch(action)
}
export const addParticipant = (participant) => (dispatch, getState) => {
  const {room} = getState();
  const isPartIndex = room.participants.findIndex(item => item.participantId === participant.participantId)
  if(isPartIndex !== -1){
    const filtered = room.participants.filter(item => item.participantId === participant.participantId)
    const action = {
      type: actionTypes.ADD_PARTICIPANT,
      payload: [...filtered, participant]
    }
    dispatch(action)
  } else {
    const action = {
      type: actionTypes.ADD_PARTICIPANT,
      payload: [...room.participants, participant]
    }
    dispatch(action)
  }

}
export const setActiveTab = (tabIndex) => (dispatch) => {
  const action = {
    type: actionTypes.SET_ACTIVE_TAB,
    payload: tabIndex
  }
  dispatch(action)
}
export const setActiveEmoji = (indexEmoji) => (dispatch) => {
  const action = {
    type: actionTypes.SET_ACTIVE_EMOJI,
    payload: indexEmoji
  }
  dispatch(action)
}

export const saveRemoteData = (remoteData) => (dispatch) => {
  const action = {
    type: actionTypes.SET_REMOTE_DATA,
    payload: remoteData
  }
  dispatch(action)
}

export const setLayout = (layout) => (dispatch) => {
  const action = {
    type: actionTypes.SET_LAYOUT,
    payload: layout
  }
  dispatch(action)
}